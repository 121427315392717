.container {
  display: flex;
  justify-content: center;
}

.editor {
  height: 100%;
  position: relative;

  .buttons {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10;
  }

  :global {
    .tui-image-editor-header-buttons,
    .tui-image-editor-header-logo,
    .tie-rotate-button,
    .tui-image-editor-menu-filter .tui-image-editor-submenu-item {
      display: none !important;
    }

    .tui-colorpicker-palette-button {
      display: block;
      overflow: hidden;
      outline: none;
      margin: 0;
      padding: 0;
      width: 16px;
      height: 16px;
      border: 1px solid #ccc;
      cursor: pointer;
    }

    .tui-colorpicker-clearfix {
      display: flex;
      flex-wrap: wrap;
    }

    .tui-image-editor-submenu {
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
    }

    .tui-image-editor-main-container {
      height: 100% !important
    }


    .tui-image-editor-help-menu.top {
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column;
      height: 506px;
      width: 40px;
      top: 55%;
      transform: translateY(-50%);

      li:nth-child(4),
      li:nth-child(9) {
        display: none;
      }
    }

    .tui-image-editor-container {
      background: none;
    }

    .tui-image-editor-container .tui-image-editor-controls {
      position: static;
    }

    .tui-image-editor-menu {
      background: #282828;
      z-index: 100;
      position: absolute;
      right: 20px;
      display: flex;
      flex-direction: column;
      top: 50%;
      padding: 10px;
      transform: translateY(-50%);
    }

    .tui-image-editor-submenu {
      & > div {
        padding-bottom: 10px !important;
      }
    }
  }
}

.bottomMenu {
  position: absolute;
  bottom: 1px;
  z-index: 200;
  left: 50%;
  transform: translateX(-50%);

  background: #ffffff;

  &.filterMenu {
    bottom: 50px;
  }

  &.rotateButtons {
    bottom: 50px;
  }
}

.zoom {
  top: 15px;
  position: absolute;
  left: 48%;
  color: black;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 10px;
}

.zoom div {
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}
